@use "../../mixins/debug";

.progress-chart {
  transform: rotate(-90deg);
}

.progress-chart-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
