@use "../../variables/colors";

.terms-conditions {
  margin-top: calc(2rem + 4px);
  margin-bottom: calc(3rem);

  .content {
    margin-top: 2rem;
    display: flex;
    gap: 16px;
    justify-content: space-between;

    .create,
    .existing {
      background: #ffffff;
      border: 1px solid #eaecf0;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
      border-radius: 8px;
    }

    .create {
      width: 43%;
      height: fit-content;

      &__body {
        padding: 20px 30px;
      }
    }

    .existing {
      flex: 1;
      height: fit-content;

      &__body {
        .term {
          display: flex;
          gap: 10px;
          justify-content: space-between;
          padding: 16px 0 13px 0;
          margin: 0 30px;
          border-bottom: 1px solid #eaecf0;

          &__heading {
            display: flex;
            gap: 16px;
            margin-bottom: 10px;

            .title {
              color: #429a24;
              font-weight: 500;
              font-size: 14px;
            }
            .date {
              font-weight: 400;
              font-size: 12px;
              color: #667085;
            }
          }

          &__content {
            color: #101828;
            font-size: 16px;
            line-height: 24px;
          }

          .delete-icon {
            cursor: pointer;
          }
        }

        .term:last-child {
          border: none;
        }
      }

      .horizontal-rule {
        border-top: 1px solid #eaecf0;
        margin-top: 5px;
        width: 100%;
      }

      .terms-conditions__pagination {
        padding: 12px 30px;
      }
    }
  }

  .heading {
    font-weight: 500;
    font-size: 18px;
    color: #101828;
    padding: 24px 30px 13px 30px;
    border-bottom: 1px solid #eaecf0;
  }
}

.alternate-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .prev,
  .next {
    border: none;
    background: none;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #475467;
    cursor: pointer;

    .arrow-icon {
      transform: translateY(1.2px);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
  .current-page {
    color: #344054;
    font-size: 14px;

    &__bold {
      font-weight: 600;
    }
  }
}
