.bar-metric-card {
  height: 249px;
  width: 310px;
  flex-grow: 1;

  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #eaecf0;

  /* padding: 16px var(--spacing-3xl, 24px); */
  padding: 16px 24px;
}

.bar-metric-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  margin-bottom: 10px;
}
.bar-metric-card__icons {
  display: flex;
  gap: 12px;
}
