.tooltip-content {
  transform-origin: var(--radix-tooltip-content-transform-origin);
  animation: scaleIn 0.2s ease-out;
  border-radius: 8px;

  /* Shadow/lg */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
