@use "../variables/breakpoints";

@mixin sm {
  @media screen and (min-width: breakpoints.$breakpoint-sm) {
    @content;
  }
}
@mixin md {
  @media screen and (min-width: breakpoints.$breakpoint-md) {
    @content;
  }
}
@mixin lg {
  @media screen and (min-width: breakpoints.$breakpoint-lg) {
    @content;
  }
}
@mixin xl {
  @media screen and (min-width: breakpoints.$breakpoint-xl) {
    @content;
  }
}
