@use "../../variables/colors";
@use "../../variables/indices";

.agent-search {
  width: 100%;
  &__trigger {
    all: unset;
    border: 1px solid colors.$gray300;
    width: 100%;
    display: block;
    cursor: pointer;
  }

  &__input {
    height: 56px;
    background-color: colors.$white;
  }
}
