@use "../../variables/colors";
@use "../../mixins/spacing";

.table-container {
  margin-top: calc(2rem + 4px);
  margin-bottom: calc(3rem);
  width: 100%;
  .table-wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(2rem + 4px);
    border-width: 1px;
    border-style: solid;
    border-radius: 0.5rem;
    border-color: colors.$gray200;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    background-color: colors.$white;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 19px;
      @include spacing.px(1.5rem);
    }
  }
  // .table-title {
  //   display: flex;
  //   gap: 0.5rem;
  //   justify-content: flex-start;
  //   padding: 1.25rem 1.5rem;
  // }
}
