@use "../../variables/indices";
@use "../../variables/colors";
@use "../../variables/radii";

@use "../../mixins/spacing";
.hover-card {
  &__table-trigger {
    @include spacing.px(1rem);
    @include spacing.py(10px);
    width: 100%;
    cursor: pointer;
    outline: none;
    border: none;
    &[data-disabled] {
      background-color: colors.$gray50;
      cursor: not-allowed;
    }
    &:hover {
      background-color: colors.$gray50;
    }
  }
  &__content {
    z-index: indices.$z-index900;
    background-color: colors.$white;
    border: 1px colors.$gray100 solid;
    z-index: indices.$z-index90;
    border-radius: radii.$lg;
    width: 240px;
    max-height: var(--radix-dropdown-menu-content-available-height);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    @include spacing.px(0.5rem);
    @include spacing.py(10px);
  }
}
