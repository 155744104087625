@mixin px($px) {
  padding-left: $px;
  padding-right: $px;
}

@mixin py($py) {
  padding-top: $py;
  padding-bottom: $py;
}

@mixin mx($mx) {
  margin-left: $mx;
  margin-right: $mx;
}

@mixin my($my) {
  margin-top: $my;
  margin-bottom: $my;
}
