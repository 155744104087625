@use "../../mixins/debug";
@use "../../mixins/spacing";
@use "../../variables/colors";
@use "../../variables/radii";

@use "../../mixins/typography";

.black-select-container {
  @include spacing.py(0px);
  display: flex;
  flex-direction: row;
  border-radius: radii.$sm;
  align-items: center;
  max-width: 90px;
  background-color: colors.$gray800;
  color: colors.$white;
}

.black-select {
  &__clear-indicator {
    // @include debug.outlineItem();
  }
  &__clear-indicator--selected {
  }
  &__clear-indicator--disabled {
  }
  &__clear-indicator--focused {
  }
  &__control {
    height: 2rem;
    @include spacing.px(0.5rem);
  }
  &__control--selected {
  }
  &__control--disabled {
  }
  &__control--focused {
  }
  &__dropdown-indicator {
    // @include debug.outlineItem();
  }
  &__dropdown-indicator--selected {
  }
  &__dropdown-indicator--disabled {
  }
  &__dropdown-indicator--focused {
  }
  &__group {
    // @include debug.outlineItem();
  }
  &__group--selected {
  }
  &__group--disabled {
  }
  &__group--focused {
  }
  &__group-heading {
    // @include debug.outlineItem();
  }
  &__group-heading--selected {
  }
  &__group-heading--disabled {
  }
  &__group-heading--focused {
  }
  &__indicators-container {
    // @include debug.outlineItem();
  }
  &__indicators-container--selected {
  }
  &__indicators-container--disabled {
  }
  &__indicators-container--focused {
  }
  &__indicator-separator {
    // @include debug.outlineItem();
  }
  &__indicator-separator--selected {
  }
  &__indicator-separator--disabled {
  }
  &__indicator-separator--focused {
  }
  &__input {
    // @include debug.outlineItem();
  }
  &__input--selected {
  }
  &__input--disabled {
  }
  &__input--focused {
  }
  &__loading-indicator {
    // @include debug.outlineItem();
  }
  &__loading-indicator--selected {
  }
  &__loading-indicator--disabled {
  }
  &__loading-indicator--focused {
  }
  &__menu {
    background-color: colors.$gray800;
    border-radius: radii.$sm;
    margin-top: 0.25rem;
  }
  &__menu--selected {
  }
  &__menu--disabled {
  }
  &__menu--focused {
  }
  &__menu-list {
    // @include debug.outlineItem();
    &:last-child {
      border-bottom-left-radius: radii.$sm;
      border-bottom-right-radius: radii.$sm;
    }
    &:first-child {
      border-top-left-radius: radii.$sm;
      border-top-right-radius: radii.$sm;
    }
  }
  &__menu-list--selected {
  }
  &__menu-list--disabled {
  }
  &__menu-list--focused {
  }
  &__menu-portal {
    // @include debug.outlineItem();
  }
  &__menu-portal--selected {
  }
  &__menu-portal--disabled {
  }
  &__menu-portal--focused {
  }
  &__multi-value {
    // @include debug.outlineItem();
  }
  &__multi-value--selected {
  }
  &__multi-value--disabled {
  }
  &__multi-value--focused {
  }
  &__multi-value-label {
    // @include debug.outlineItem();
  }
  &__multi-value-label--selected {
  }
  &__multi-value-label--disabled {
  }
  &__multi-value-label--focused {
  }
  &__multi-value-remove {
    // @include debug.outlineItem();
  }
  &__multi-value-remove--selected {
  }
  &__multi-value-remove--disabled {
  }
  &__multi-value-remove--focused {
  }
  &__no-options-message {
    // @include debug.outlineItem();
  }
  &__no-options-message--selected {
  }
  &__no-options-message--disabled {
  }
  &__no-options-message--focused {
  }
  &__option {
    @include spacing.py(0.5rem);
    @include spacing.px(0.5rem);
    &:hover {
      background-color: colors.$gray600;
    }
  }
  &__option--is-selected {
    background-color: colors.$gray900;
  }
  &__option--disabled {
  }
  &__option--focused {
  }
  &__placeholder {
  }
  &__placeholder--selected {
  }
  &__placeholder--disabled {
  }
  &__placeholder--focused {
  }
  &__single-value {
    // @include debug.outlineItem();
  }
  &__single-value--selected {
    background-color: colors.$gray400;
    color: colors.$gray900;
  }
  &__single-value--disabled {
  }
  &__single-value--focused {
  }
  &__value-container {
    // @include debug.outlineItem();
  }
  &__value-container--selected {
  }
  &__value-container--disabled {
  }
  &__value-container--focused {
  }
}
