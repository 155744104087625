@use "../../variables/colors";

.accordion {
  background-color: colors.$white;
  width: 100%;

  &__item {
    overflow: hidden;
    margin-top: 1px;

    &:focus-within {
      position: relative;
      z-index: 1;
    }
  }
  &__header {
    display: flex;
  }

  &__trigger {
    display: flex;
    padding: 0px 8px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: colors.$gray700;
    padding: 4px 8px;
    border: 0px;
    border-radius: 4px;
    cursor: pointer;

    /* Text sm/Medium */
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    background-color: colors.$gray50;
    &:hover {
      background-color: colors.$gray100;
    }
    & > .accordion-chevron {
      transform: translateY(2px);
    }
    &[data-state="open"] > .accordion-chevron {
      transform: rotate(180deg) translateY(2px);
    }
  }

  &__content {
    overflow: hidden;
    background-color: colors.$white;
    &[data-state="open"] {
      animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
    &[data-state="closed"] {
      animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
  }

  .accordion-chevron {
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
