// font families
$default-font: "TT Hoves";
$mono-font: ui-monospace SFMono-Regular Menlo Monaco Consolas "Liberation Mono" "Courier New" monospace;
$organic-font: ui-sans-serif system-ui -apple-system BlinkMacSystemFont "Segoe UI" Roboto "Helvetica Neue" Arial "Noto Sans"
  sans-serif "Apple Color Emoji" "Segoe UI Emoji" "Segoe UI Symbol" "Noto Color Emoji";
$serif-font: ui-serif Georgia Cambria "Times New Roman" Times serif;

// font sizes
$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-md: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.25rem;
$font-size-displayXs: 1.5rem;
$font-size-displaySm: 1.875rem;
$font-size-displayMd: 2.25rem;
$font-size-displayLg: 3rem;
$font-size-displayXl: 3.75rem;
$font-size-display2xl: 4.5rem;

// line heghts
$line-height-xs: 1.125rem;
$line-height-sm: 1.25rem;
$line-height-md: 1.5rem;
$line-height-lg: 1.75rem;
$line-height-xl: 1.875rem;
$line-height-displayXs: 2rem;
$line-height-displaySm: 2.375rem;
$line-height-displayMd: 2.75rem;
$line-height-displayLg: 3.75rem;
$line-height-displayXl: 4.5rem;
$line-height-display2xl: 5.625rem;

// letter spacing
$tracking-xs: normal;
$tracking-sm: normal;
$tracking-md: normal;
$tracking-lg: normal;
$tracking-xl: normal;
$tracking-displayXs: normal;
$tracking-displaySm: normal;
$tracking-displayMd: -2%;
$tracking-displayLg: -2%;
$tracking-displayXl: -2%;
$tracking-display2xl: -2%;

// font weights
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
