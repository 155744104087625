@use "../../variables/colors";

.divider {
  &--light {
    background-color: colors.$gray100;
  }
  &--dark {
    background-color: colors.$gray200;
  }
  &[data-orientation="horizontal"] {
    height: 1px;
    width: 100%;
  }
  &[data-orientation="vertical"] {
    height: 100%;
    width: 1px;
  }
}
