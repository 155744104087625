@use "../../variables/colors";

@use "../../mixins/spacing";

.inner-tabs {
  display: flex;
  flex-direction: column;
  background-color: colors.$gray25;
  @include spacing.py(8px);
  @include spacing.px(24px);
  &__header {
    flex-direction: row;
    display: flex;
    background-color: colors.$gray50;
    border-radius: 8px;
    gap: 0px 8px;
    border: 1px solid colors.$gray100;
    padding: 5px;
  }
  &__trigger {
    background-color: transparent;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border: 1px solid colors.$gray100;
    border-radius: 6px;
    color: colors.$gray500;
    &[data-status="active"] {
      background-color: colors.$white;
      color: colors.$gray700;
      /* Shadow/sm */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    }
  }
}
