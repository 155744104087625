@use "../../variables/colors";
@use "../../variables/sizes";
@use "../../variables/indices";
@use "../../variables/radii";
@use "../../variables/breakpoints";

@use "../../mixins/debug";
@use "../../mixins/spacing";
@use "../../mixins/typography";
@use "../../mixins/mediaQueries";

// DASHBOARD
.dashboard-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: colors.$white;
  max-width: 100vw;

  &__header-container {
    z-index: indices.$z-index50;
    background-color: transparent;
  }
  &__content-container {
    position: relative;
    z-index: indices.$z-index20;
    background-color: transparent;
  }
}

.profile-dropdown {
  &__content {
    background-color: colors.$white;
    border: 1px colors.$gray100 solid;
    z-index: indices.$z-index90;
    border-radius: radii.$lg;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  }
  &__trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    margin-right: 1.5rem;
  }
  svg.dropdown-arrow {
    fill: white;
  }
  &__label {
    background-color: colors.$white;
    @include spacing.px(1rem);
    @include spacing.py(12px);
  }
  &__item {
    @include spacing.px(1rem);
    @include spacing.py(10px);
    cursor: pointer;
    outline: none;
    border: none;
    &:hover {
      background-color: colors.$primary50;
      color: colors.$primary500;
    }
  }
  &__logout-item {
    @include spacing.px(1rem);
    @include spacing.py(10px);
    cursor: pointer;
    outline: none;
    border: none;
    &:hover {
      background-color: colors.$error50;
      color: colors.$error500;
    }
  }
  &__separator {
    background-color: colors.$gray100;
    height: 1px;
  }
}
.dashboard-content {
  display: flex;
  flex-direction: row;
  margin-top: sizes.$dashboard-header-height;
  max-height: sizes.$dashboard-content-height;
  background-color: colors.$gray25;
  &__sidebar-panel {
    position: relative;
    background-color: colors.$white;

    &--expanded {
      width: sizes.$dashboard-sidebar-expanded-width;
    }
    &--collapsed {
      width: sizes.$dashboard-sidebar-collapsed-width;
    }
  }

  &__main-panel {
    height: sizes.$dashboard-content-height;
    overflow-y: auto;
    &--expanded {
      width: calc(100vw - sizes.$dashboard-sidebar-expanded-width);
    }
    &--collapsed {
      width: calc(100vw - sizes.$dashboard-sidebar-collapsed-width);
    }
  }
}

// SIDEBAR
.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  border-right: 1px colors.$gray200 solid;
  background-color: colors.$white;
  top: sizes.$dashboard-header-height;
  left: 0px;
  bottom: 0px;
  max-height: sizes.$dashboard-content-height;
  @include spacing.py(2rem);

  &--expanded {
    width: sizes.$dashboard-sidebar-expanded-width;
    .sidebar-brand {
      width: 262px;
    }
  }

  &--collapsed {
    width: sizes.$dashboard-sidebar-collapsed-width;
    .sidebar-brand {
      width: 60px;
    }
  }
}

.sidebar-brand {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 42px;
  &__toggle {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    &:focus,
    &:hover {
      background-color: colors.$primary25;
      border: none;
      outline: none;
    }
  }
}

.sidebar-top {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  flex-basis: 80%;
  overflow-y: auto;
  position: relative;
  margin: 1rem 0;

  &--expanded {
    width: sizes.$sidebar-expanded-inner-width;
  }
  &--collapsed {
    width: sizes.$sidebar-collapsed-inner-width;
  }
}

.sidebar-bottom {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  flex-basis: 10%;
  padding-top: 1rem;
  border-top: 1px colors.$gray200 solid;
  height: 100%;

  &--expanded {
    width: sizes.$sidebar-expanded-inner-width;
  }
  &--collapsed {
    width: sizes.$sidebar-collapsed-inner-width;
  }
}

// PAGE WRAPPER
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - sizes.$dashboard-header-height);
  margin: 0px auto;
  // padding: 1rem;
  // width: 100%;
  @include mediaQueries.md {
    padding: 2rem;
    max-width: breakpoints.$breakpoint-xxl;
  }
}
