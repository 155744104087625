@use "../mixins/debug";

@mixin size-chart-grid-item() {
}

.overview-chart-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;

  &__right {
    display: flex;
    flex-direction: column;
  }

  &__left {
    display: flex;
    flex-direction: column;
  }
}
