@use "../variables/sizes";
@use "../variables/colors";
// progress loader overlay styles
// COPY from nprogress.css
/* Make clicks pass-through */

#nprogress {
  /* Make clicks pass-through */
  pointer-events: none;
  overflow: hidden;
  .bar {
    background: colors.$primary500;
    position: fixed;
    z-index: 1031;
    top: calc(sizes.$dashboard-header-height + 2px);
    left: 0;

    width: 100%;
    height: 2px;
  }
  // /* Fancy blur effect */
  // .peg {
  //   display: block;
  //   position: absolute;
  //   right: 0px;
  //   width: 100px;
  //   height: 100%;
  //   box-shadow: 0 0 10px colors.$primary400, 0 0 5px colors.$primary500;
  //   opacity: 1;

  //   -webkit-transform: rotate(3deg) translate(0px, -4px);
  //   -ms-transform: rotate(3deg) translate(0px, -4px);
  //   transform: rotate(3deg) translate(0px, -4px);
  // }

  /* Remove these to get rid of the spinner */
  .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: calc(sizes.$dashboard-header-height + 8px);
    right: 1rem;
  }

  .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: colors.$primary500;
    border-left-color: colors.$primary900;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 500ms linear infinite;
    animation: nprogress-spinner 500ms linear infinite;
  }
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
