@use "../../variables/colors";

@use "../../mixins/spacing";

%unstyle-button {
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

%unset-spacing {
  padding: 0px;
  margin: 0px;
}

.pagination {
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  border: 1px solid colors.$gray300;
  border-radius: 0.5rem;
  overflow: hidden;
  // @include spacing.px(1rem);
  height: 40px;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));

  &__previous-btn,
  &__next-btn {
    @include spacing.px(1rem);
    background-color: colors.$white;
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: colors.$gray50;
    }
    &:disabled {
      background-color: colors.$gray50;
      cursor: not-allowed;
    }
  }
  &__previous-btn {
    border-right: 1px solid colors.$gray300;
  }
}

.pagination-nav {
  @extend %unset-spacing;
  &__list {
    @extend %unset-spacing;
    display: flex;
    flex-direction: row;
    li {
      // @extend %unset-spacing;
      list-style: none;
      &:last-child {
        border: none;
      }
    }
  }
  &__page-btn {
    @extend %unstyle-button;
    @include spacing.px(1rem);
    cursor: pointer;
    height: 2.5rem;
    background-color: colors.$white;
    border-right: 1px solid colors.$gray300;
    &:hover {
      background-color: colors.$gray50;
    }

    &--active {
      background-color: colors.$gray100;
    }
  }
}

.alternate-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .prev,
  .next {
    border: none;
    background: none;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #475467;
    cursor: pointer;

    .arrow-icon {
      transform: translateY(1.2px);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
  .current-page {
    color: #344054;
    font-size: 14px;

    &__bold {
      font-weight: 600;
    }
  }
}
