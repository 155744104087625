// radii
$none: 0px;
$xs: 0.125rem;
$sm: 0.25rem;
$md: 0.375rem;
$lg: 0.5rem;
$xl: 0.75rem;
$xxl: 1rem;
$xxxl: 1.5rem;
$full: 9999px;
