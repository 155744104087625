@use "../../variables/colors";
@use "../../variables/sizes";
@use "../../variables/indices";
@use "../../variables/radii";

@use "../../mixins/debug";
@use "../../mixins/spacing";
@use "../../mixins/typography";

.page-tabs {
  display: flex;
  flex-direction: column;
}

.page-tabs__list {
  display: flex;
  border-bottom: 1px solid colors.$gray200;
  gap: 1rem;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.page-tabs__trigger {
  font-size: 14px;
  color: colors.$gray500;
  user-select: none;
  white-space: nowrap;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0 5px 18px 5px;
  &:hover {
    color: colors.$primary600;
  }
  &[data-state="active"],
  &.active {
    color: colors.$primary600;
    border-bottom: 2px solid colors.$primary600;
    padding-bottom: 16px;
  }
}

.page-tabs__content {
}
