@import-normalize;

/* TT Hoves */
/* black */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Black.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Black.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Black.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Black.woff2") format("webfont");
}
/* black italic */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-BlackItalic.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-BlackItalic.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-BlackItalic.woff2") format("webfont");
}
/* bold */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Bold.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Bold.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Bold.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Bold.woff2") format("webfont");
}
/* bold italic */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-BoldItalic.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-BoldItalic.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-BoldItalic.woff2") format("webfont");
}
/* demibold  */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-DemiBold.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-DemiBold.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-DemiBold.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-DemiBold.woff2") format("webfont");
}
/* demi bold italic */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-DemiBoldItalic.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-DemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-DemiBoldItalic.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-DemiBoldItalic.woff2") format("webfont");
}
/* extra bold italic */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraBold.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraBold.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraBold.woff2") format("webfont");
}
/* Extra bold italic */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraBoldItalic.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraBoldItalic.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraBoldItalic.woff2") format("webfont");
}
/* Extra light */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraLight.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraLight.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraLight.woff2") format("webfont");
}
/* Extra light italic */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraLightItalic.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraLightItalic.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraLightItalic.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ExtraLightItalic.woff2") format("webfont");
}
/* hairline */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Hairline.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Hairline.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Hairline.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Hairline.woff2") format("webfont");
}
/* hairline italic */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-HairlineItalic.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-HairlineItalic.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-HairlineItalic.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-HairlineItalic.woff2") format("webfont");
}
/* italic */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Italic.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Italic.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Italic.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Italic.woff2") format("webfont");
}
/* Light */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Light.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Light.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Light.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Light.woff2") format("webfont");
}
/* Light Italic */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-LightItalic.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-LightItalic.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-LightItalic.woff2") format("webfont");
}
/* Medium */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Medium.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Medium.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Medium.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Medium.woff2") format("webfont");
}
/* Medium italic */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-MediumItalic.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-MediumItalic.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-MediumItalic.woff2") format("webfont");
}
/* Regular*/
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Regular.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Regular.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Regular.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Regular.woff2") format("webfont");
}
/* Thin */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Thin.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Thin.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Thin.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-Thin.woff2") format("webfont");
}
/* Thin Italic */
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ThinItalic.eot") format("embedded opentype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ThinItalic.ttf") format("truetype");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ThinItalic.woff") format("webfont");
}
@font-face {
  font-family: TTHoves;
  src: url("../fonts/TT\ Hoves/TTHoves-ThinItalic.woff2") format("webfont");
}
