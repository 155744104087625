body,
html {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  font-size: 16px;
  font-family: "TT Hoves", Arial, Helvetica, sans-serif;
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
}
p {
  display: block;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.show-password:hover {
  cursor: pointer;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #429a24;
}
button {
  display: unset;
}
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  outline: 1px solid #d0d5dd;
  border: 0px;
  padding: 1rem;
  font-family: "TT Hoves", Arial, Helvetica, sans-serif;
}
.modal-textarea {
  height: 10rem !important;
}

/* google maps hack */
.gmnoprint a,
.gmnoprint span {
  display: none;
  background: none !important;
}
.gmnoprint div {
  display: none;
}

/* img[src="http://maps.gstatic.com/mapfiles/api-3/images/google_white2.png"], a[href^="http://maps.google.com/maps"]{
  display:none !important;
} */
img[alt="Google"], a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

button[aria-label="Keyboard shortcuts"] {
  display: none !important;
}
