@use "../../variables/colors";

@use "../../mixins/spacing";
@use "../../mixins/debug";
.block-item {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: colors.$gray25;
  width: 237px;
  cursor: pointer;
  border: 1px solid colors.$gray200;
  // @include spacing.px(1rem);
  padding-left: 1rem;
  @include spacing.py(0.5rem);
  @include spacing.my(12px);
  position: relative;

  &:hover {
    background: colors.$primary25;
  }
  &[data-status="active"][data-error-state="enabled"] {
    border: 1px solid colors.$error200;
    box-shadow: 0px 0px 0px 4px colors.$error25;
    background: colors.$error25;
  }
  &[data-status="active"][data-error-state="disabled"] {
    border: 1px solid colors.$gray200;
    background: colors.$primary25;
    box-shadow: 0px 0px 0px 4px #ecffe5;
    &:hover {
      background: colors.$primary25;
    }
    .block-item__title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.4px;
    }
  }
  &[data-error-state="enabled"] {
    border: 1px solid colors.$error200;
    &:hover {
      background: colors.$error25;
    }
    // outline: 1px solid colors.$error200;
  }

  &__icon {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 28px;
    border: 4px solid #f9fafb;
    background: #f2f4f7;
  }
  &__content {
    flex: 3;
    max-width: 137px;
    margin-left: 1.25rem;
  }
  &__title {
    overflow: hidden;
    width: 100%;
    color: colors.$gray700;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.35px;
    text-align: left;
  }
  &__subtitle {
    color: colors.$gray500;
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.25px;
  }

  &__actions {
    position: absolute;
    right: 1rem;
  }
}

.mobile-preview {
  height: 100%;
  background-color: colors.$gray100;
  display: flex;
  justify-content: center;
  .phone-case {
    height: 562px;
    width: 279px;
    margin-top: 32px;
    background: black;
    border-radius: 40px;
    border: 10px black ridge;
    overflow: hidden;
    position: relative;

    .phone-speaker {
      //   @include debug.outlineItem();
      width: 140px;
      height: 32px;
      position: absolute;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.9);
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      left: 25%;
      .speaker-bar {
        background-color: #101010;
        height: 5px;
        width: 45px;
        border-radius: 25px;
        margin-top: 10px;
        display: inline-block;
      }
      .camera {
        background-color: #101010;
        height: 5px;
        width: 5px;
        border-radius: 25px;
        display: inline-block;
        margin-left: 5px;
      }
    }

    .phone-screen {
      background-color: colors.$gray50;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      padding: 10px 0px;
      box-sizing: border-box;

      display: grid;
      grid-template-rows: 1fr 1fr 12fr;
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
        "time . . reception"
        "title title title title"
        "content content content content";

      .screen-time {
        grid-area: time;
        font-size: 14px;
        margin-left: 10px;
      }

      .screen-reception {
        grid-area: reception;
        position: relative;
        padding-left: 15px;
        padding-top: 5px;
        .screen-reception-bars,
        .screen-reception-wifi,
        .screen-reception-battery {
          position: absolute;
          top: 0px;
        }
        .screen-reception-bars {
          left: 20px;
        }
        .screen-reception-wifi {
          left: 50px;
        }
        .screen-reception-battery {
          left: 35px;
        }
      }
      .screen-title-bar {
        display: flex;
        justify-content: space-between;
        height: 32px;
        // margin-top: 24px;
        // width: 100%;
        border-bottom: colors.$gray200 1px solid;
        text-align: center;
        grid-area: title;
        padding: 0px 15px;
      }
      .screen-content {
        grid-area: content;
        height: 100%;
        background-color: colors.$white;
        padding: 10px 15px;
        overflow-y: auto;
      }
    }
  }
}

.add-block {
  height: 58px;
  width: 255px;
  position: relative;
  margin-top: 12px;
  &[data-status="open"] {
    height: 100%;
  }
}

.add-block-trigger {
  all: unset;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #deffd2;
  width: 237px;
  cursor: pointer;
  padding-left: 1rem;
  max-height: 58px;
  @include spacing.py(0.5rem);
  background: colors.$primary25;

  &__icon {
    display: inline-flex;
    width: 32px;
    height: 32px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 28px;
    border: 4px solid colors.$primary50;
    background: colors.$primary100;
  }
  &__content {
    display: inline-flex;
    flex: 3;
    max-width: 137px;
    margin-left: 1rem;
  }
  &__title {
    color: #429a24;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.4px;
  }
}

.block-options {
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: colors.$primary25;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  // gap: 16px 0px;

  &__close {
    all: unset;
    height: 28px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 1rem;
    @include spacing.py(0.5rem);
    cursor: pointer;

    .close-icon {
      margin-left: 1rem;
    }

    .close-label {
      color: #429a24;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.4px;
      margin-left: 1rem;
    }
  }

  &__item {
    all: unset;
    height: 28px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    // @include spacing.px(1rem);
    @include spacing.py(0.5rem);
    padding-left: 0.5rem;
    cursor: pointer;
    // border: 1px solid colors.$gray200;
    &:hover {
      background-color: colors.$primary100;
      .block-option-title {
        color: colors.$gray600;
      }
      .block-option-icon {
        background: colors.$primary25;
      }
    }

    .block-option-icon {
      display: inline-flex;
      width: 24px;
      height: 24px;
      padding: 2px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 4px;
      margin-left: 1rem;
      background: colors.$primary100;
    }
    .block-option-title {
      color: colors.$gray900;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.4px;
      // flex: 3;
      max-width: 137px;
      margin-left: 1.25rem;
    }
  }
}
