@use "../../variables/colors";
@use "../../variables/radii";
@use "../../variables/indices";

.popover-content {
  display: flex;
  visibility: hidden;
  background-color: colors.$white;
  padding: 1rem;
  border-radius: radii.$lg;
  border: 1px solid colors.$gray100;
  z-index: indices.$z-index90;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &--show {
    visibility: visible;
  }
}

.popover-content[data-state="open"][data-side="top"] {
  animation-name: slideDownAndFade;
}
.popover-content[data-state="open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}
.popover-content[data-state="open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.popover-content[data-state="open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

.popover-reference {
  display: inline-block;
}

.default-popover {
  z-index: indices.$z-index90;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.default-popover-close {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  &:hover {
    background-color: colors.$error50;
    & svg {
      fill: colors.$error500;
    }
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
