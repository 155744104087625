.metric-card-container {
  height: 249px;
  width: 310px;
  flex-grow: 1;

  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #eaecf0;

  padding: 16px var(--spacing-3xl, 24px);
}

.metric-card-container__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}
.metric-card-container__icons {
  display: flex;
  gap: 12px;
}

.metric-card-container__value {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  height: 100%;

  color: var(--Gray-900);
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;
}
