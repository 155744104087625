@use "../../variables/colors";
@use "../../variables/sizes";
@use "../../variables/radii";
@use "../../variables/indices";

@use "../../mixins/debug";
@use "../../mixins/spacing";
@use "../../mixins/typography";

.select-container {
  outline: colors.$gray300 1px solid;
  height: 44px;
  @include spacing.py(0px);
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: colors.$white;
  color: colors.$gray700;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  // z-index: indices.$z-index90;
}

.select {
  &__clear-indicator {
    // @include debug.outlineItem();
  }
  &__clear-indicator--selected {
  }
  &__clear-indicator--disabled {
  }
  &__clear-indicator--focused {
  }
  &__container {
    // @include debug.outlineItem(red);
  }
  &__container--selected {
  }
  &__container--disabled {
  }
  &__container--focused {
  }
  &__control {
    @include spacing.px(0.5rem);
    display: flex;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
  }
  &__control--selected {
  }
  &__control--disabled {
  }
  &__control--focused {
  }
  &__dropdown-indicator {
    // @include debug.outlineItem();
  }
  &__dropdown-indicator--selected {
  }
  &__dropdown-indicator--disabled {
  }
  &__dropdown-indicator--focused {
  }
  &__group {
    // @include debug.outlineItem();
  }
  &__group--selected {
  }
  &__group--disabled {
  }
  &__group--focused {
  }
  &__group-heading {
    // @include debug.outlineItem();
  }
  &__group-heading--selected {
  }
  &__group-heading--disabled {
  }
  &__group-heading--focused {
  }
  &__indicators-container {
    // @include debug.outlineItem();
  }
  &__indicators-container--selected {
  }
  &__indicators-container--disabled {
  }
  &__indicators-container--focused {
  }
  &__indicator-separator {
    // @include debug.outlineItem();
  }
  &__indicator-separator--selected {
  }
  &__indicator-separator--disabled {
  }
  &__indicator-separator--focused {
  }
  &__input {
    // @include debug.outlineItem();
  }
  &__input--selected {
  }
  &__input--disabled {
  }
  &__input--focused {
  }
  &__loading-indicator {
    // @include debug.outlineItem();
  }
  &__loading-indicator--selected {
  }
  &__loading-indicator--disabled {
  }
  &__loading-indicator--focused {
  }
  &__loading-message {
    // @include debug.outlineItem();
  }
  &__loading-message--selected {
  }
  &__loading-message--disabled {
  }
  &__loading-message--focused {
  }
  &__menu {
    @include debug.outlineItem();
    margin: 0px;
    background-color: colors.$white;
    outline: colors.$gray300 1px solid;

    margin-top: 0.25rem;
  }
  &__menu--selected {
  }
  &__menu--disabled {
  }
  &__menu--focused {
  }
  &__menu-list {
    // @include debug.outlineItem();
  }
  &__menu-list--selected {
  }
  &__menu-list--disabled {
  }
  &__menu-list--focused {
  }
  &__menu-portal {
    // @include debug.outlineItem();
  }
  &__menu-portal--selected {
  }
  &__menu-portal--disabled {
  }
  &__menu-portal--focused {
  }
  &__multi-value {
    // @include debug.outlineItem();
    // @include debug.outlineItem();
    padding: 2px 8px;
    margin-right: 0.5rem;
    border-radius: 1rem;
    background-color: colors.$gray50;
    color: colors.$gray700;
    text-align: center;

    /* Text xs/Medium */
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    &:hover {
      background-color: colors.$gray200;
      color: colors.$gray900;
    }
  }
  &__multi-value--selected {
  }
  &__multi-value--disabled {
  }
  &__multi-value--focused {
  }
  &__multi-value__label {
    &--selected {
    }
    &--disabled {
    }
    &--focused {
    }
  }
  &__multi-value__remove {
    // @include debug.outlineItem();
    cursor: pointer;
    margin-left: 4px;
  }
  &__multi-value-remove--selected {
  }
  &__multi-value-remove--disabled {
  }
  &__multi-value-remove--focused {
  }
  &__no-options-message {
    // @include debug.outlineItem();
    color: colors.$gray300;
  }
  &__no-options-message--selected {
  }
  &__no-options-message--disabled {
  }
  &__no-options-message--focused {
  }
  &__option {
    // @include debug.outlineItem();
    @include spacing.py(0.5rem);
    @include spacing.px(0.5rem);

    cursor: pointer;

    &:hover {
      background-color: colors.$gray100;
    }
  }
  &__option--is-selected {
    background-color: colors.$gray300;
    color: colors.$gray900;
  }
  &__option--disabled {
  }
  &__option--focused {
  }
  &__placeholder {
    // @include debug.outlineItem();
  }
  &__placeholder--selected {
  }
  &__placeholder--disabled {
  }
  &__placeholder--focused {
  }
  &__single-value {
    // @include debug.outlineItem();
  }
  &__single-value--selected {
  }
  &__single-value--disabled {
  }
  &__single-value--focused {
  }
  &__value-container {
    // @include debug.outlineItem();
  }
  &__value-container--selected {
  }
  &__value-container--disabled {
  }
  &__value-container--focused {
  }
}
