@use "../../variables/colors";

@use "../../mixins/typography";
@use "../../mixins/spacing";
@use "../../mixins/debug";
@use "../../mixins/scrollbar";

.table-element-wrapper {
  overflow: auto;
  display: block;
  @include scrollbar.scrollbar-hide();
}

.table-element {
  border-spacing: 0;
  // border-bottom: 1px solid colors.$gray200;
  //   @include debug.outlineItem();

  .table-head {
    overflow-y: auto;
    background-color: colors.$gray50;
    @include scrollbar.scrollbar-hide();

    &:hover {
      .resizer {
        opacity: 1;
      }
    }

    &__tr {
      // border-bottom: 1px solid colors.$gray200;
      .th {
        user-select: none;
        padding: 12px 24px;
        text-align: left;
        white-space: nowrap;
        background-color: colors.$gray50;

        color: colors.$gray500;
        @include typography.xsMedium();
      }
    }
  }

  .table-body {
    overflow-y: scroll;
    max-height: 346px;
    background-color: colors.$white;

    &__tr {
      // border-bottom: 1px solid colors.$gray200;
      &:hover {
        background-color: colors.$gray50;
      }

      .td {
        width: 100%;
        color: colors.$gray500;
        padding: 16px 24px;
        background-color: colors.$white;
        white-space: nowrap;
        text-align: left;
        @include typography.smMedium();
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .td,
  .th {
    margin: 0;
    position: relative; // becaause resizer is absolutely positioned
  }
  .resizer {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    display: flex;
    width: 3px;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    // prevents from scrolling while dragging on touch devices
    touch-action: none;
    background: colors.$gray200;
    &.isResizing {
      background: colors.$gray600;
      opacity: 1;
    }
  }
}
