@use "../../variables/colors";

.form-grid-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: colors.$gray700;
  padding: 4px 8px;
  border: 0px;
  border-radius: 4px;

  /* Text sm/Medium */
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: colors.$gray50;
  &:hover {
    background-color: colors.$gray100;
  }
}
