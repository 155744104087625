@use "../../variables/colors";
@use "../../variables/indices";
@use "../../variables/radii";

@use "../../mixins/typography";
@use "../../mixins/spacing";

%select-input {
  &__trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: colors.$white;
    border: 1px solid colors.$gray300;
    color: colors.$gray800;
    cursor: pointer;

    &:hover {
      background-color: colors.$gray50;
    }
    &:focus {
      border: 2px solid colors.$primary300;
      outline: 2px solid colors.$primary50;
    }
    &[data-placeholder] {
      color: colors.$gray500;
    }
  }

  &__content {
    z-index: indices.$z-index90;
    background-color: colors.$white;
    border: 1px solid colors.$gray300;
    overflow: hidden;
  }
}

%select-input-option {
  cursor: pointer;
  @include spacing.px(1rem);
  @include spacing.py(10px);

  &:hover {
    background-color: colors.$gray50;
  }
}

.select-input-default {
  &__trigger {
    @extend %select-input__trigger;
  }

  &__content {
    @extend %select-input__content;
  }
}
.select-input-rounded {
  &__trigger {
    @extend %select-input__trigger;
    border-radius: radii.$lg;
  }

  &__content {
    @extend %select-input__content;
    border-radius: radii.$lg;
  }
}

.select-input-option {
  @extend %select-input-option;
}
