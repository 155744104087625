.insight-analytics-custom-dashboard {
  display: flex;
  border-top: 1px solid #eaecf0;
}

.insight-analytics-custom-dashboard_left {
  margin-top: 24px;
  margin-right: 24px;
  width: 830px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 30px;
  flex: 2;
}

.insight-analytics-custom-dashboard_right {
  border-left: 1px solid #eaecf0;
  /* flex: 1; */
  max-width: 310px;
  min-height: 200px;
  padding: 24px;
}
