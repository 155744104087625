$white: #ffffff;
$gray25: #fcfcfd;
$gray50: #f9fafb;
$gray100: #f2f4f7;
$gray200: #eaecf0;
$gray300: #d0d5dd;
$gray400: #98a2b3;
$gray500: #667085;
$gray600: #475467;
$gray700: #344054;
$gray800: #1d2939;
$gray900: #101828;
$primary25: #f9fff6;
$primary50: #ecffe5;
$primary100: #deffd2;
$primary200: #b5f2a0;
$primary300: #84c56f;
$primary400: #64b548;
$primary500: #48a928;
$primary600: #429a24;
$primary700: #247809;
$primary800: #196201;
$primary900: #054f31;
$error25: #fffbfa;
$error50: #fef3f2;
$error100: #fee4e2;
$error200: #fecdca;
$error300: #fda29b;
$error400: #f97066;
$error500: #f04438;
$error600: #d92d20;
$error700: #b42318;
$error800: #912018;
$error900: #7a271a;
$warning25: #fffcf5;
$warning50: #fffaeb;
$warning100: #fef0c7;
$warning200: #fedf89;
$warning300: #fec84b;
$warning400: #fdb022;
$warning500: #f79009;
$warning600: #dc6803;
$warning700: #b54708;
$warning800: #93370d;
$warning900: #7a2e0e;
$success25: #f6fef9;
$success50: #ecfdf3;
$success100: #d1fadf;
$success200: #a6f4c5;
$success300: #6ce9a6;
$success400: #32d583;
$success500: #12b76a;
$success600: #039855;
$success700: #027a48;
$success800: #05603a;
$success900: #054f31;
$blueGray25: #fcfcfd;
$blueGray50: #f8f9fc;
$blueGray100: #eaecf5;
$blueGray200: #d5d9eb;
$blueGray300: #afb5d9;
$blueGray400: #717bbc;
$blueGray500: #4e5ba6;
$blueGray600: #3e4784;
$blueGray700: #363f72;
$blueGray800: #293056;
$blueGray900: #101323;
$blueLight25: #f5fbff;
$blueLight50: #f0f9ff;
$blueLight100: #e0f2fe;
$blueLight200: #b9e6fe;
$blueLight300: #7cd4fd;
$blueLight400: #36bffa;
$blueLight500: #0ba5ec;
$blueLight600: #0086c9;
$blueLight700: #026aa2;
$blueLight800: #065986;
$blueLight900: #0b4a6f;
$blue25: #f5faff;
$blue50: #eff8ff;
$blue100: #d1e9ff;
$blue200: #b2ddff;
$blue300: #84caff;
$blue400: #53b1fd;
$blue500: #2e90fa;
$blue600: #1570ef;
$blue700: #175cd3;
$blue800: #1849a9;
$blue900: #194185;
$indigo25: #f5f8ff;
$indigo50: #eef4ff;
$indigo100: #e0eaff;
$indigo200: #c7d7fe;
$indigo300: #a4bcfd;
$indigo400: #8098f9;
$indigo500: #6172f3;
$indigo600: #444ce7;
$indigo700: #3538cd;
$indigo800: #2d31a6;
$indigo900: #2d3282;
$purple25: #fafaff;
$purple50: #f4f3ff;
$purple100: #ebe9fe;
$purple200: #d9d6fe;
$purple300: #bdb4fe;
$purple400: #9b8afb;
$purple500: #7a5af8;
$purple600: #6938ef;
$purple700: #5925dc;
$purple800: #4a1fb8;
$purple900: #3e1c96;
$pink25: #fef6fb;
$pink50: #fdf2fa;
$pink100: #fce7f6;
$pink200: #fcceee;
$pink300: #faa7e0;
$pink400: #f670c7;
$pink500: #ee46bc;
$pink600: #dd2590;
$pink700: #c11574;
$pink800: #9e165f;
$pink900: #851651;
$rose25: #fff5f6;
$rose50: #fff1f3;
$rose100: #ffe4e8;
$rose200: #fecdd6;
$rose300: #fea3b4;
$rose400: #fd6f8e;
$rose500: #f63d68;
$rose600: #e31b54;
$rose700: #c01048;
$rose800: #a11043;
$rose900: #89123e;
$orange25: #fffaf5;
$orange50: #fff6ed;
$orange100: #ffead5;
$orange200: #fddcab;
$orange300: #feb273;
$orange400: #fd853a;
$orange500: #fb6514;
$orange600: #ec4a0a;
$orange700: #c4320a;
$orange800: #9c2a10;
$orange900: #7e2410;
