@use "../../variables/colors";

.horizontal-divider {
  width: 100%;
  height: 1px;
  background-color: #eaecf0;
  position: relative;

  &__text {
    color: #667085;
    font-size: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 0 10px;
  }
}
