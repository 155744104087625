@use "../../variables/colors";

.switch {
  all: unset;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(#ecffe5, 0);
  &:focus {
    box-shadow: 0px 0px 0px 4px #ecffe5;
  }

  &--sm {
    width: 36px;
    height: 20px;
    border-radius: 12px;

    & .switch__thumb {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 16px;

      transition: transform 100ms;
      transform: translateX(2px);
      will-change: transform;
      &[data-state="checked"] {
        transform: translateX(19px);
      }
    }
  }
  &--md {
    width: 44px;
    height: 24px;
    border-radius: 12px;
    & .switch__thumb {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 20px;

      transition: transform 100ms;
      transform: translateX(2px);
      will-change: transform;
      &[data-state="checked"] {
        transform: translateX(22px);
      }
    }
  }

  &--light {
    background-color: colors.$gray100;
    & .switch__thumb {
      background-color: white;

      /* Shadow/sm */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    }
    &[data-state="checked"] {
      background-color: colors.$primary600;
    }
  }

  &--dark {
    background-color: colors.$gray200;
    & .switch__thumb {
      background-color: white;

      /* Shadow/sm */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    }
    &[data-state="checked"] {
      background-color: colors.$primary600;
    }
  }
}
