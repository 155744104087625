.shimmer {
  &-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 20px;
    display: inline-block;
  }

  &-thumbnail {
    min-width: 80px;
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    display: inline-block;
  }
}
