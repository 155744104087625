.pie-metric-card {
  height: 249px;
  width: 310px;
  flex-grow: 1;

  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #eaecf0;

  padding: 16px var(--spacing-3xl, 24px);
}

.pie-metric-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}
.pie-metric-card__icons {
  display: flex;
  gap: 12px;
}

.pie-metric-card__value {
  margin-top: 8px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
