@use "../variables/colors";

.warehouse-summary {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 5px;
    margin-top: 2rem;
    background-color: colors.$gray100;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  }

  &__gridItem {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    padding: 2rem;
    background-color: colors.$white;
    border-left: 2px solid transparent;
    transition: all 0.3s ease-in-out;

    &:hover {
      // This color is not in the palette
      background: #f9fff7;
      border-left: 2px solid colors.$primary500;
    }

    &__Title {
      color: colors.$gray500;
      font-size: 14px;
      font-weight: 500;
    }

    &__Value {
      color: colors.$gray900;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
