@use "../../variables/indices";

@use "../../mixins/mediaQueries";

.dialog-content {
  z-index: indices.$z-index80;
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  overflow-y: auto;
  //   left: 477px;
  cursor: default;
  background-color: white;
  width: 100vw;
  &--md {
    width: 100vw;
  }

  @include mediaQueries.sm {
    width: 70vw;
    &--md {
      width: 70vw;
    }
  }
  @include mediaQueries.lg {
    width: 477px;
    &--md {
      width: 60vw;
    }
  }

  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  &--center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // width: 90vw;
    height: fit-content !important;
    max-width: 90vw;
    padding: 0;
    animation: contentShowCenter 200ms cubic-bezier(0.16, 1, 0.3, 1);
    border-radius: 12px;
  }
}

.dialog-overlay {
  position: fixed;
  left: 0;
  top: 0;
  //   align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 12, 12, 0.5);
  backdrop-filter: blur(3px);
  z-index: indices.$z-index80;
}

.dialog-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  border: 0px;
  outline: 0px;
  cursor: pointer;
  color: #344054;
  background-color: white;
  &:hover {
    color: #1d2939;
  }
  &:focus {
    color: #344054;
  }
  &:disabled {
    color: #d0d5dd;
    border: 1px solid #eaecf0;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    right: -100%;
  }
  to {
    opacity: 1;
    right: 0;
  }
}
@keyframes contentShowCenter {
  from {
    opacity: 0;
    scale: 0;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}
