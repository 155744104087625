@use "../../variables/colors";

.checkbox {
  all: unset;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  outline: none;
  background-color: colors.$white;
  border: 1px solid colors.$gray300;
  &:hover {
    background-color: colors.$primary50;
    border: 1px solid colors.$primary600;
  }
  &[data-state="checked"] {
    background-color: colors.$primary50;
    border: 1px solid colors.$primary600;
  }
  &--sm {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    &__label {
      /* Text sm/Medium */
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }

  &--md {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    &__label {
      /* Text md/Medium */
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }

  &__label {
    color: colors.$gray700;
    display: inline;
    margin-left: 8px;
    user-select: none;
  }
}

.checkbox-wrapper {
  all: unset;
  margin: 0px;
  padding: 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
