@use "../variables/fonts";

// default font-family
%default-font {
  // font-family: fonts.$default-font;
}

// typograpghy mixins
@mixin xsRegular {
  @extend %default-font;
  font-weight: fonts.$font-weight-regular;
  font-size: fonts.$font-size-xs;
  line-height: fonts.$line-height-xs;
  letter-spacing: fonts.$tracking-xs;
}
@mixin xsMedium {
  @extend %default-font;
  font-weight: fonts.$font-weight-medium;
  font-size: fonts.$font-size-xs;
  line-height: fonts.$line-height-xs;
  letter-spacing: fonts.$tracking-xs;
}
@mixin xsSemibold {
  @extend %default-font;
  font-weight: fonts.$font-weight-semibold;
  font-size: fonts.$font-size-xs;
  line-height: fonts.$line-height-xs;
  letter-spacing: fonts.$tracking-xs;
}
@mixin xsBold {
  @extend %default-font;
  font-weight: fonts.$font-weight-bold;
  font-size: fonts.$font-size-xs;
  line-height: fonts.$line-height-xs;
  letter-spacing: fonts.$tracking-xs;
}
@mixin smRegular {
  @extend %default-font;
  font-weight: fonts.$font-weight-regular;
  font-size: fonts.$font-size-sm;
  line-height: fonts.$line-height-sm;
  letter-spacing: fonts.$tracking-sm;
}
@mixin smMedium {
  @extend %default-font;
  font-weight: fonts.$font-weight-medium;
  font-size: fonts.$font-size-sm;
  line-height: fonts.$line-height-sm;
  letter-spacing: fonts.$tracking-sm;
}
@mixin smSemibold {
  @extend %default-font;
  font-weight: fonts.$font-weight-semibold;
  font-size: fonts.$font-size-sm;
  line-height: fonts.$line-height-sm;
  letter-spacing: fonts.$tracking-sm;
}
@mixin smBold {
  @extend %default-font;
  font-weight: fonts.$font-weight-bold;
  font-size: fonts.$font-size-sm;
  line-height: fonts.$line-height-sm;
  letter-spacing: fonts.$tracking-sm;
}
@mixin mdRegular {
  @extend %default-font;
  font-weight: fonts.$font-weight-regular;
  font-size: fonts.$font-size-md;
  line-height: fonts.$line-height-md;
  letter-spacing: fonts.$tracking-md;
}
@mixin mdMedium {
  @extend %default-font;
  font-weight: fonts.$font-weight-medium;
  font-size: fonts.$font-size-md;
  line-height: fonts.$line-height-md;
  letter-spacing: fonts.$tracking-md;
}
@mixin mdSemibold {
  @extend %default-font;
  font-weight: fonts.$font-weight-semibold;
  font-size: fonts.$font-size-md;
  line-height: fonts.$line-height-md;
  letter-spacing: fonts.$tracking-md;
}
@mixin mdBold {
  @extend %default-font;
  font-weight: fonts.$font-weight-bold;
  font-size: fonts.$font-size-md;
  line-height: fonts.$line-height-md;
  letter-spacing: fonts.$tracking-md;
}
@mixin lgRegular {
  @extend %default-font;
  font-weight: fonts.$font-weight-regular;
  font-size: fonts.$font-size-lg;
  line-height: fonts.$line-height-lg;
  letter-spacing: fonts.$tracking-lg;
}
@mixin lgMedium {
  @extend %default-font;
  font-weight: fonts.$font-weight-medium;
  font-size: fonts.$font-size-lg;
  line-height: fonts.$line-height-lg;
  letter-spacing: fonts.$tracking-lg;
}
@mixin lgSemibold {
  @extend %default-font;
  font-weight: fonts.$font-weight-semibold;
  font-size: fonts.$font-size-lg;
  line-height: fonts.$line-height-lg;
  letter-spacing: fonts.$tracking-lg;
}
@mixin lgBold {
  @extend %default-font;
  font-weight: fonts.$font-weight-bold;
  font-size: fonts.$font-size-lg;
  line-height: fonts.$line-height-lg;
  letter-spacing: fonts.$tracking-lg;
}
@mixin xlRegular {
  @extend %default-font;
  font-weight: fonts.$font-weight-regular;
  font-size: fonts.$font-size-xl;
  line-height: fonts.$line-height-xl;
  letter-spacing: fonts.$tracking-xl;
}
@mixin xlMedium {
  @extend %default-font;
  font-weight: fonts.$font-weight-medium;
  font-size: fonts.$font-size-xl;
  line-height: fonts.$line-height-xl;
  letter-spacing: fonts.$tracking-xl;
}
@mixin xlSemibold {
  @extend %default-font;
  font-weight: fonts.$font-weight-semibold;
  font-size: fonts.$font-size-xl;
  line-height: fonts.$line-height-xl;
  letter-spacing: fonts.$tracking-xl;
}
@mixin xlBold {
  @extend %default-font;
  font-weight: fonts.$font-weight-bold;
  font-size: fonts.$font-size-xl;
  line-height: fonts.$line-height-xl;
  letter-spacing: fonts.$tracking-xl;
}

// display text typography mixins
@mixin displayXsRegular {
  @extend %default-font;
  font-weight: fonts.$font-weight-regular;
  font-size: fonts.$font-size-displayXs;
  line-height: fonts.$line-height-displayXs;
  letter-spacing: fonts.$tracking-displayXs;
}
@mixin displayXsMedium {
  @extend %default-font;
  font-weight: fonts.$font-weight-medium;
  font-size: fonts.$font-size-displayXs;
  line-height: fonts.$line-height-displayXs;
  letter-spacing: fonts.$tracking-displayXs;
}
@mixin displayXsSemibold {
  @extend %default-font;
  font-weight: fonts.$font-weight-semibold;
  font-size: fonts.$font-size-displayXs;
  line-height: fonts.$line-height-displayXs;
  letter-spacing: fonts.$tracking-displayXs;
}
@mixin displayXsBold {
  @extend %default-font;
  font-weight: fonts.$font-weight-bold;
  font-size: fonts.$font-size-displayXs;
  line-height: fonts.$line-height-displayXs;
  letter-spacing: fonts.$tracking-displayXs;
}
@mixin displaySmRegular {
  @extend %default-font;
  font-weight: fonts.$font-weight-regular;
  font-size: fonts.$font-size-displaySm;
  line-height: fonts.$line-height-displaySm;
  letter-spacing: fonts.$tracking-displaySm;
}
@mixin displaySmMedium {
  @extend %default-font;
  font-weight: fonts.$font-weight-medium;
  font-size: fonts.$font-size-displaySm;
  line-height: fonts.$line-height-displaySm;
  letter-spacing: fonts.$tracking-displaySm;
}
@mixin displaySmSemibold {
  @extend %default-font;
  font-weight: fonts.$font-weight-semibold;
  font-size: fonts.$font-size-displaySm;
  line-height: fonts.$line-height-displaySm;
  letter-spacing: fonts.$tracking-displaySm;
}
@mixin displaySmBold {
  @extend %default-font;
  font-weight: fonts.$font-weight-bold;
  font-size: fonts.$font-size-displaySm;
  line-height: fonts.$line-height-displaySm;
  letter-spacing: fonts.$tracking-displaySm;
}
@mixin displayMdRegular {
  @extend %default-font;
  font-weight: fonts.$font-weight-regular;
  font-size: fonts.$font-size-displayMd;
  line-height: fonts.$line-height-displayMd;
  letter-spacing: fonts.$tracking-displayMd;
}
@mixin displayMdMedium {
  @extend %default-font;
  font-weight: fonts.$font-weight-medium;
  font-size: fonts.$font-size-displayMd;
  line-height: fonts.$line-height-displayMd;
  letter-spacing: fonts.$tracking-displayMd;
}
@mixin displayMdSemibold {
  @extend %default-font;
  font-weight: fonts.$font-weight-semibold;
  font-size: fonts.$font-size-displayMd;
  line-height: fonts.$line-height-displayMd;
  letter-spacing: fonts.$tracking-displayMd;
}
@mixin displayMdBold {
  @extend %default-font;
  font-weight: fonts.$font-weight-bold;
  font-size: fonts.$font-size-displayMd;
  line-height: fonts.$line-height-displayMd;
  letter-spacing: fonts.$tracking-displayMd;
}
@mixin displayLgRegular {
  @extend %default-font;
  font-weight: fonts.$font-weight-regular;
  font-size: fonts.$font-size-displayLg;
  line-height: fonts.$line-height-displayLg;
  letter-spacing: fonts.$tracking-displayLg;
}
@mixin displayLgMedium {
  @extend %default-font;
  font-weight: fonts.$font-weight-medium;
  font-size: fonts.$font-size-displayLg;
  line-height: fonts.$line-height-displayLg;
  letter-spacing: fonts.$tracking-displayLg;
}
@mixin displayLgSemibold {
  @extend %default-font;
  font-weight: fonts.$font-weight-semibold;
  font-size: fonts.$font-size-displayLg;
  line-height: fonts.$line-height-displayLg;
  letter-spacing: fonts.$tracking-displayLg;
}
@mixin displayLgBold {
  @extend %default-font;
  font-weight: fonts.$font-weight-bold;
  font-size: fonts.$font-size-displayLg;
  line-height: fonts.$line-height-displayLg;
  letter-spacing: fonts.$tracking-displayLg;
}
@mixin displayXlRegular {
  @extend %default-font;
  font-weight: fonts.$font-weight-regular;
  font-size: fonts.$font-size-displayXl;
  line-height: fonts.$line-height-displayXl;
  letter-spacing: fonts.$tracking-displayXl;
}
@mixin displayXlMedium {
  @extend %default-font;
  font-weight: fonts.$font-weight-medium;
  font-size: fonts.$font-size-displayXl;
  line-height: fonts.$line-height-displayXl;
  letter-spacing: fonts.$tracking-displayXl;
}
@mixin displayXlSemibold {
  @extend %default-font;
  font-weight: fonts.$font-weight-semibold;
  font-size: fonts.$font-size-displayXl;
  line-height: fonts.$line-height-displayXl;
  letter-spacing: fonts.$tracking-displayXl;
}
@mixin displayXlBold {
  @extend %default-font;
  font-weight: fonts.$font-weight-bold;
  font-size: fonts.$font-size-displayXl;
  line-height: fonts.$line-height-displayXl;
  letter-spacing: fonts.$tracking-displayXl;
}
@mixin display2xlRegular {
  @extend %default-font;
  font-weight: fonts.$font-weight-lRegular;
  font-size: fonts.$font-size-display2x;
  line-height: fonts.$line-height-display2x;
  letter-spacing: fonts.$tracking-display2x;
}
@mixin display2xlMedium {
  @extend %default-font;
  font-weight: fonts.$font-weight-lMedium;
  font-size: fonts.$font-size-display2x;
  line-height: fonts.$line-height-display2x;
  letter-spacing: fonts.$tracking-display2x;
}
@mixin display2xlSemibold {
  @extend %default-font;
  font-weight: fonts.$font-weight-lSemibold;
  font-size: fonts.$font-size-display2x;
  line-height: fonts.$line-height-display2x;
  letter-spacing: fonts.$tracking-display2x;
}
@mixin display2xlBold {
  @extend %default-font;
  font-weight: fonts.$font-weight-lBold;
  font-size: fonts.$font-size-display2x;
  line-height: fonts.$line-height-display2x;
  letter-spacing: fonts.$tracking-display2x;
}
