@use "../../variables/colors";

@use "../../mixins/spacing";

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid colors.$gray200;
  @include spacing.my(2rem);

  &__header {
    height: 79px;
    background-color: colors.$white;
    display: inline-flex;
    align-items: center;
    width: 100%;
    @include spacing.px(24px);
    overflow: hidden;
    // outline: solid 10px red;
  }
  &--transparent {
    border: none;
  }
  &--transparent {
    border: none;
  }
  &__body {
    width: 100%;
    background-color: colors.$white;
    overflow-x: auto;
    position: relative;

    &--transparent {
      background-color: transparent;
      border: none;
    }
    &--default {
      background-color: colors.$white;
    }
  }
}
