@use "../../variables/colors";
@use "../../variables/sizes";
@use "../../variables/indices";
@use "../../variables/radii";

@use "../../mixins/debug";
@use "../../mixins/spacing";
@use "../../mixins/typography";

.default-dropdown {
  &__content {
    background-color: colors.$white;
    border: 1px colors.$gray100 solid;
    z-index: indices.$z-index90;
    border-radius: radii.$lg;
    min-width: 200px;
    max-height: var(--radix-dropdown-menu-content-available-height);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    &[data-side="top"] {
      animation-name: slideDownAndFade;
    }
    &[data-side="right"] {
      animation-name: slideLeftAndFade;
    }
    &[data-side="bottom"] {
      animation-name: slideUpAndFade;
    }
    &[data-side="left"] {
      animation-name: slideRightAndFade;
    }
  }
  &__trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    user-select: none;
  }
  &__label {
    background-color: colors.$white;
    @include spacing.px(1rem);
    @include spacing.py(12px);
  }
  &__item {
    @include spacing.px(1.5rem);
    @include spacing.py(13px);
    cursor: pointer;
    outline: none;
    border: none;
    &[data-disabled] {
      background-color: colors.$gray50;
      cursor: not-allowed;
    }
    &:hover {
      background-color: colors.$gray50;
    }
  }
  &__separator {
    background-color: colors.$gray100;
    height: 1px;
  }
}

.default-dropdown-submenu {
  &__content {
    background-color: colors.$white;
    border: 1px colors.$gray100 solid;
    z-index: indices.$z-index90;
    border-radius: radii.$lg;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    width: var(--radix-dropdown-menu-trigger-width);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    &[data-side="top"] {
      animation-name: slideDownAndFade;
    }
    &[data-side="right"] {
      animation-name: slideLeftAndFade;
    }
    &[data-side="bottom"] {
      animation-name: slideUpAndFade;
    }
    &[data-side="left"] {
      animation-name: slideRightAndFade;
    }
  }
  &__trigger {
    @include spacing.px(1rem);
    @include spacing.py(10px);
    cursor: pointer;
    outline: none;
    border: none;
    &[data-disabled] {
      background-color: colors.$gray50;
      cursor: not-allowed;
    }
    &:hover {
      background-color: colors.$gray50;
    }
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
